import React, {useCallback, useState, useEffect, useRef} from 'react';
import styled from "styled-components";
import {create} from "braintree-web-drop-in";
import {useTranslation} from "react-i18next";
import {IconButton} from "@mui/material";
import FunctionsApiService from "services/FunctionsApiService";

import ButtonPrimary from "./PrimaryButton";
import IconSuccess from "./IconSuccess";
import LoadingIndicator from "./LoadingIndicator";

import * as Icon from "react-feather";
import { colors, fonts } from "styles/stylesProxy";

let braintreeInstance;

export default function PaymentMethodSelection({isDisabled, onStarted, onPaymentSelected, on3DSValidationFailed, formData, updateFormData, price}){

    const { t, i18n } = useTranslation();

    const [isStarted, setIsStarted] = useState(false);
    const [isBraintreeInitialised, setIsBraintreeInitialised] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const initRetryCounter = useRef(0);
    const initRetryTimeoutId = useRef(null);

    // make sure that the price is in the expected format
    price = parseFloat(price).toFixed(2);

    const onInitBraintree = useCallback(async () => {
        setErrorMessage('');
        setIsLoading(true);
        updateFormData({ braintreeRequestable: false });

        // make sure that the container is always empty before the braintreeInstance is created
        const braintreeContainer = document.getElementById('braintree-container');
        braintreeContainer.innerHTML = '';

        updateFormData({
            braintreeType: '',
            braintreeEmail: '',
            braintreeCardType: '',
            braintreeCardLastTwo: '',
            braintreeCardLastFour: '',
            braintreeNonce: '',
        });

        try {
            const data = await FunctionsApiService.paymentsClientToken();
            create({
                authorization: data.data.token,
                container: '#braintree-container',
                locale: i18n.language,
                paypal: {
                    flow: 'vault',
                    buttonStyle: {
                        color: 'gold',
                        shape: 'rect',
                        size: 'responsive',
                        tagline: false
                    }
                },
                threeDSecure: true,
                card: {
                    overrides: {
                        fields: {
                            number: {
                                supportedCardBrands: {
                                    "maestro": false
                                },
                            }
                        }
                    }
                }
                /*
                applePay: {
                  displayName: "Asservato GmbH",
                  paymentRequest: {

                  }
                },*/
                /*
                googlePay: {
                  merchantId: 'BCR2DN4TWC6IJXYZ',
                  transactionInfo: {
                    currencyCode: 'EUR',
                    countryCode: 'DE',
                    totalPriceStatus: 'FINAL',
                    totalPrice: price.toString(),
                  }
                }*/
            }, (createErr, instance) => {

                if (createErr) {
                    console.error(createErr);
                    return;
                }

                braintreeInstance = instance;

                if (instance.isPaymentMethodRequestable()) {
                    updateFormData({ braintreeRequestable: true });
                }

                instance.on('paymentMethodRequestable', (event) => {
                    updateFormData({ braintreeRequestable: true });
                });

                instance.on('noPaymentMethodRequestable', () => {
                    updateFormData({ braintreeRequestable: false });
                });

                initRetryCounter.current = 0;
                setIsLoading(false);
                setIsBraintreeInitialised(true);
            })

        } catch(error) {
            let message = t('error');

            if (error && error.message) {
                console.log("Init error: ", error.message);
            }

            if(initRetryCounter.current < 5) {
                initRetryCounter.current += 1;

                if(initRetryTimeoutId.current) clearTimeout(initRetryTimeoutId.current);

                initRetryTimeoutId.current = setTimeout(() => {
                    onInitBraintree();
                }, 1000);

            } else {
                setIsLoading(false);
                setErrorMessage(message);
                initRetryCounter.current = 0;
            }
        }

    }, [updateFormData, i18n.language, t])

    useEffect(() => {
        onInitBraintree();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [price])

    const startSelection = useCallback( () => {
        onStarted();
        setIsStarted(true);
    }, [onStarted, setIsStarted])

    const onContinue = useCallback(async () => {

        setIsLoading(true);
        setErrorMessage('');

        if(!price) {
            console.warn("No price was set!");
            return;
        }

        var threeDSecureParameters = {
            amount: price.toString(),//grandTotal.toString(),
            challengeRequested: true,
            email: formData.email,
            billingAddress: {
                givenName: formData.firstName,
                surname: formData.lastName,
                streetAddress: formData.address.street + ' ' + formData.address.streetNo,
                locality: formData.address.city,
                postalCode: formData.address.zip,
                countryCodeAlpha2: formData.address.country,
            }
        };

        const promise = new Promise((resolve, reject) => {
            braintreeInstance.requestPaymentMethod(
                {
                    threeDSecure: threeDSecureParameters
                },
                (requestPaymentMethodErr, payload) => {

                    if (requestPaymentMethodErr) {
                        reject(requestPaymentMethodErr.message);
                        return;
                    }

                    if(payload.liabilityShiftPossible && !payload.liabilityShifted){
                        on3DSValidationFailed(payload.threeDSecureInfo.status);
                        reject({message:t('paymentMethodSelection.threeDSecureValidationError')});
                        return;
                    }

                    resolve(payload);
                });
        });

        try {
            const data = await promise;
            onPaymentSelected(data);
            setIsLoading(false);

            updateFormData({
                braintreeType: data.type,
                braintreeEmail: data.type === 'PayPalAccount' ? data.details.email : '',
                braintreeCardType: data.type === 'PayPalAccount' ? 'PayPal' : data.details.cardType,
                braintreeCardLastTwo: data.type === 'PayPalAccount' ? '' : data.details.lastTwo,
                braintreeCardLastFour: data.type === 'PayPalAccount' ? '' : data.details.lastFour,
                braintreeNonce: data.nonce,
            });
        } catch (error){

            let message = "";

            if (error && error.message) {
                message = error.message;
            }
            if (!error || error === 'Invalid request data') {
                message = t('error');
            }

            setIsLoading(false);
            setErrorMessage(message);
        }
    }, [formData, updateFormData, t, onPaymentSelected, on3DSValidationFailed, price])

    return (
        <Container>
            {((!isBraintreeInitialised) || !isStarted) && !formData.braintreeNonce && !errorMessage && !isLoading
                ?   <ButtonPrimary label={t('paymentMethodSelection.paymentCta')} onClick={startSelection} marginTop={16}/>
                :   null
            }

            {isLoading
                ?   <LoadingIndicator padding={'12px'}/>
                :   null
            }

            <BraintreeContainer visible={isStarted && isBraintreeInitialised && !errorMessage && !isLoading && !formData.braintreeNonce}>
                <div id="braintree-container"></div>
                {formData.braintreeRequestable
                    ?   <ButtonPrimary label={t('continue')} onClick={onContinue} isDisabled={!formData.braintreeRequestable} marginTop={16}/>
                    :   null
                }
            </BraintreeContainer>

            {errorMessage
                ?   <>
                    <ErrorMessageContainer>
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                    </ErrorMessageContainer>
                    <ButtonPrimary label={t('paymentMethodSelection.paymentCta')} onClick={onInitBraintree} marginTop={16}/>
                </>
                :   null
            }

            {formData.braintreeNonce
                ?   <SummaryContainer>
                    <Left>
                        <IconSuccess/>
                        <Text>
                            <Name>{formData.braintreeCardType}</Name><br/>
                            <Description>{formData.braintreeEmail || t('paymentMethodSelection.cardEnding', {lastFour:formData.braintreeCardLastFour})}</Description>
                        </Text>
                    </Left>

                    {!isDisabled
                        ?   <IconButton onClick={onInitBraintree} disabled={isDisabled}>
                            <Icon.Edit color={colors.primary.primary_350} size={20}/>
                        </IconButton>
                        :   null
                    }
                </SummaryContainer>
                :   null
            }

            <div id="braintree-end"></div>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
`;

const BraintreeContainer = styled.div`
    width: 100%;
    display: ${ props => props.visible ? 'block' : 'none'};
    
    #braintree-container {
        //width: 100%;
    }
    
    .braintree-placeholder {
        display: none;
    }

    .braintree-sheet {
        border: 1px solid ${colors.primary.primary_450};
        border-radius: 0;
    }
    
    .braintree-sheet__header{
        border-bottom: 1px solid ${colors.primary.primary_450};
    }

    .braintree-option {
        border: 1px solid ${colors.primary.primary_450};
        background: #fff;
        
        &:first-child {
            border-bottom: none;
        }
        
        &:hover {
            background: ${colors.primary.primary_500};
        }
    }
`;

const ErrorMessageContainer = styled.div`
    border-radius: 3px;
    background: ${colors.semantic.warning_light};
    padding: 24px;
`;

const ErrorMessage = styled(fonts.Body)`
    color: ${colors.semantic.warning_dark};
    line-height: 14px;
`;

const SummaryContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px 24px 24px;
    border: 1px solid ${colors.primary.primary_450};
    width: 100%;
    box-sizing: border-box;
    text-align: left;
`;

const Left = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`;

const Text = styled.div`
    margin-left: 24px;
`;

const Name = styled(fonts.Title)`
    color: ${colors.primary.primary_100}
`;

const Description = styled(fonts.Body)`
  color: ${colors.primary.primary_100};
`;
