import React from 'react';
import styled, { css } from "styled-components";
import * as Icon from "react-feather";
import {colors, fonts} from 'styles/stylesProxy';
import RadioButton from "./RadioButton";

export default function ItemIdentificationMethod({title, text, image, features, isActive, isDisabled, isSummary, onClick, id}){

    return (
        <ItemContainer isActive={isActive} isDisabled={isDisabled} isSummary={isSummary} onClick={onClick && !isDisabled ? () => onClick(id) : null}>
            <div id={"top-border"}/>
            {!isSummary
                ?   <div>
                    <RadioButton value={0} isActive={isActive} disableHover={true}/>
                </div>
                :   null
            }

            <Content isSummary={isSummary}>
                <Main>
                    <Description>
                        <fonts.Title>
                            {title}
                        </fonts.Title>
                        <fonts.LabelRegularSmall>
                            {text}
                        </fonts.LabelRegularSmall>
                    </Description>
                    <div>
                        <TypeImage src={image} alt={""}/>
                    </div>
                </Main>

                {features
                    ?   <ChecklistContainer>
                        {features.map((feature, i) => {
                            return (
                                <ChecklistItem key={i}>
                                    <div>
                                        <Icon.Check size={16} strokeWidth={3} color={colors.secondary.secondary_100}/>
                                    </div>
                                    <span>{feature}</span>
                                </ChecklistItem>
                            )
                        })}
                    </ChecklistContainer>
                    :   null
                }
            </Content>
        </ItemContainer>
    )

}

const ItemContainer = styled.div`
    border-left: ${props => props.isActive ? `1px solid ${colors.secondary.secondary_100}` : `1px solid ${colors.primary.primary_450}`};
    border-right: ${props => props.isActive ? `1px solid ${colors.secondary.secondary_100}` : `1px solid ${colors.primary.primary_450}`};
    border-bottom: ${props => props.isActive ? `1px solid ${colors.secondary.secondary_100}` : `1px solid ${colors.primary.primary_450}`};
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    #top-border {
        height: 1px;
        width: 100%;
        background: ${props => props.isActive ? `${colors.secondary.secondary_100}` : 'transparent'};
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
    }
    
    ${(props) => {
    if(!props.isActive && !props.isSummary && !props.isDisabled){
        return css`
                &:hover {
                    border-left: 1px solid ${colors.primary.primary_300};
                    border-right: 1px solid ${colors.primary.primary_300};
                    border-bottom: 1px solid ${colors.primary.primary_300};
                    cursor: pointer;

                    #top-border {
                        background: ${colors.primary.primary_300};
                    }
                }
            `;
    }
}}
`;

const Content = styled.div`
    margin-left: ${props => props.isSummary ? null : '24px'};
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Main = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
`;

const TypeImage = styled.img`
    max-width: 60px;
    margin-left: 24px;
`;

const ChecklistContainer = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: column;
`;

const ChecklistItem = styled.div`
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    & div {
        display: flex;
        align-items: center;
    }
    
    & span {
        padding-left: 8px;
    }
`;
