import React from 'react';
import styled, { css } from 'styled-components';
import {colors, fonts} from 'styles/stylesProxy';
import RadioButton from 'components/RadioButton';
import IconSuccess from './IconSuccess';

export default function ItemContactDetails({name, email, phone, company, isSummary, onClick, id}){

    return (
        <Container isSummary={isSummary} onClick={onClick ? () => onClick(id) : null}>
            {isSummary
                ? <IconSuccess/>
                : <RadioButton disableHover={true}/>
            }
            <Right>
                <Name>{name}</Name><br/>

                <DetailsContainer>
                    <ContactDetail>{email}</ContactDetail><br/>
                    {phone && <><ContactDetail>{phone}</ContactDetail><br/></>}
                </DetailsContainer>
                {company && <Company>{company}</Company>}
            </Right>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    border: ${props => props.isActive ? `1px solid ${colors.secondary.secondary_100}` : `1px solid ${colors.primary.primary_450}`};
    margin-bottom: ${props => props.isLast ? 0 : "12px"};
    cursor: ${props => !props.isSummary ? 'pointer' : 'inherit'};
    
    ${(props) => {
    if(props.isDisabled){
        return css`
                    border: 1px dashed ${colors.primary.primary_450};
                `;
    } else if(!props.isActive && !props.isSummary){
        return css`
                    &:hover {
                        border: 1px solid ${colors.primary.primary_300};
                    }
                `;
    }
}}

`;

const Right = styled.div`
  margin-left: 24px;
`;

const Name = styled(fonts.Title)`
  color: ${colors.primary.primary_100};
`;

const DetailsContainer = styled.div`
  //line-height: 16px;
`;

const ContactDetail = styled(fonts.LabelRegularMedium)`
  color: ${colors.primary.primary_100};
`;

const Company = styled(fonts.LabelRegularMedium)`
  color: ${colors.primary.primary_100};
  display: block;
  margin-top: 8px;
`;
