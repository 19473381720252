import styled from 'styled-components';
import {fonts, colors, breakpoints} from "styles/stylesProxy";
import React from "react";
import {getDateStringFromTimestamp, getFormattedCurrency, getFormattedPrice} from "utils/mobile-app-helpers";
import { Mail, Download } from 'feather-icons-react';
import Tooltip from '@mui/material/Tooltip';
import {useAppState} from "contexts/AppStateContext";

export default function InvoiceListItem ({invoiceData, onSendInvoice, onDownloadInvoice}){

    const {strings} = useAppState();
    let dateString = getDateStringFromTimestamp(invoiceData.billingAt);

    const onSend = (e) => {
        e.preventDefault();
        onSendInvoice(invoiceData.id);
    }

    const onDownload = (e) => {
        onDownloadInvoice();
    }

    return (
        <Container>
            <Left>
                <Date>{dateString}</Date>

                <Stack>
                    <InvoiceNumber>{invoiceData.invoiceNumber}</InvoiceNumber>
                    <fonts.LabelRegularSmall>{strings['INVOICES_VIEW_INVOICE_TOTAL'] + ": " + getFormattedPrice(invoiceData.data.pricing.total) + " " + getFormattedCurrency(invoiceData.data.pricing.currency)}</fonts.LabelRegularSmall>
                </Stack>
            </Left>
            <ActionContainer >

                <Tooltip title={strings['INVOICES_VIEW_TOOLTIP_DOWNLOAD']} placement={"bottom"}>
                    <ActionButton href={'data:application/pdf;base64,'+invoiceData.pdf} download={invoiceData.invoiceNumber+'.pdf'} target='_blank' onClick={onDownload}>
                        <Download size={24}/>
                    </ActionButton>
                </Tooltip>

                <Tooltip title={strings['INVOICES_VIEW_TOOLTIP_SEND']} placement={"bottom"}>
                    <ActionButton onClick={onSend}>
                        <Mail size={24}/>
                    </ActionButton>
                </Tooltip>
            </ActionContainer>
        </Container>
    )

}

const Container = styled.div`
    padding: 16px 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.primary.primary_400};
    text-decoration: none;
    

    @media ${breakpoints.mobileL} {
        padding: 16px 16px;
        background-color: ${colors.primary.primary_500};
        margin-bottom: 12px;
        border-radius: 5px;
        border-bottom: none;
    }
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    @media ${breakpoints.mobileL} {
        flex-direction: row;
        align-items: center;
    }
`;

const Stack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    @media ${breakpoints.mobileL} {
        padding-left: 32px;
    }
`;

const Date = styled(fonts.LabelRegularSmall)`
    color: ${colors.primary.primary_300};
`;

const InvoiceNumber = styled(fonts.LabelSemiBold)`
    color: ${colors.primary.primary_100};

    
`;

const ActionContainer = styled.div`
    display: flex;
    
`;

const ActionButton = styled.a`
    
    width:40px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: ${colors.tertiary.tertiary_100} !important;
    
    :hover {
        cursor: pointer;
        background: ${colors.primary.primary_450};    
    }
`;
