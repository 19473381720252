import React, {useState} from 'react';
import styled from 'styled-components';

import DropdownLocationItem from './DropdownLocationItem';

export default function DropdownLocation({items, onSelection, selectedToken, subtitleSoonAvailable}){

    const [isOpen, setIsOpen] = useState(false);

    if(!items ||items.length === 0) return null;

    const selectedItem = items.find(el => el.token === selectedToken);
    const address = selectedItem ? selectedItem.address.split(',') : null;

    function onSelect(token){
        onSelection(token);
        setIsOpen(false);
    }

    if(!selectedItem) return null;

    return (
        <>
            <Container>
                <DropdownLocationItem
                    name={selectedItem.name}
                    street={address[0]}
                    city={address[1]}
                    onClick={() => setIsOpen(!isOpen)}
                    isActive={isOpen}
                    hasIcon={true}
                />
            </Container>

            {isOpen
                ?   <ListContainer>
                    {items.map((el, i)=> {

                        if(el.token === selectedToken) return null;
                        const isDisabled = el.status === "IN_PREPARATION";
                        let subtitle = subtitleSoonAvailable;
                        let addressSplit = el.status === 'OPEN' ? el.address.split(',') : '';

                        return <DropdownLocationItem
                            name={el.name}
                            street={!isDisabled ? addressSplit[0] : subtitle}
                            city={!isDisabled ? addressSplit[1] : null}
                            isDisabled={isDisabled}
                            key={i}
                            onClick={!isDisabled ? () => onSelect(el.token) : null}
                        />
                    })}
                </ListContainer>
                :   null
            }

        </>
    );
}

const Container = styled.div`
    
`;

const ListContainer = styled.div`
    background: white;
    position: absolute;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0,0,0,.1);
    z-index: 10;
    margin-top:16px;
`;
