import React, {useState, useCallback} from "react";
import styled from 'styled-components';
import {useAppState} from "contexts/AppStateContext";
import {useSnackbar} from "notistack";
import {userDashboardPaths} from "../../constants/paths";
import {useHistory} from "react-router-dom";
import {isValidEmail} from "services/InputValidationService";

import TextInput from "../../components/TextInput";
import PrimaryButton from "../../components/PrimaryButton";
import LoadingIndicator from "../../components/LoadingIndicator";

import {breakpoints, colors} from "styles/stylesProxy";
import {Body, LabelRegularSmall, LabelSemiBold} from "../../styles/fontStyles";
import {registerUser} from "../../services/AuthenticationService";

export default function RegisterView() {
    const {strings} = useAppState();
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showHelperDialog, setShowHelperDialog] = useState(false);

    const isPasswordValid = passwordRepeat.length >= 6 && passwordRepeat === password;
    const isPasswordMismatch = password.length >= 6 && passwordRepeat.length >= password.length && !isPasswordValid;

    const onConfirm = useCallback(async () => {
        setIsLoading(true)

        try {
            await registerUser(email, password);
            setIsLoading(false)
            history.replace(userDashboardPaths.boxesOverview);
        } catch(error) {
            setIsLoading(false)
            if(error?.code === 'auth/email-already-in-use') {
                setShowHelperDialog(true);
            } else {
                let message = strings["ERROR_auth/default"];
                if (error && error.message) {
                    message = strings["ERROR_" + error.code];
                }
                enqueueSnackbar(message, {variant: 'error'});
            }
        }

    }, [email, password, enqueueSnackbar, history, strings]);

    return (
      <View>
          <Container>
            <LogoContainer>
                <Logo src={'/assets/logo_asservato.png'}/>
            </LogoContainer>

            {!showHelperDialog
                ?   <Description>
                        {strings['REGISTER_VIEW_DESCRIPTION']}
                    </Description>
                : null
            }

            <TextInput name={"email"}
                       label={strings['REGISTER_VIEW_PLACEHOLDER_EMAIL']}
                       value={email}
                       onChange={(e) => {
                           setEmail(e.target.value);
                           setShowHelperDialog(false);
                       }}
                       isValid={isValidEmail(email)}
                       margin={"32px 0 0 0"}
            />

            {!showHelperDialog
                ?   <>
                        <TextInput name={"password"}
                                   label={strings['REGISTER_VIEW_PLACEHOLDER_PASSWORD']}
                                   value={password}
                                   onChange={(e) => setPassword(e.target.value)}
                                   isValid={password.length >= 6}
                                   margin={'32px 0 0 0'}
                                   type={'password'}
                        />

                        <TextInput name={"passwordRepeat"}
                                   label={strings['REGISTER_VIEW_PLACEHOLDER_PASSWORD_REPEAT']}
                                   value={passwordRepeat}
                                   onChange={(e) => setPasswordRepeat(e.target.value)}
                                   isValid={isPasswordValid}
                                   margin={'16px 0 0 0'}
                                   type={'password'}
                                   error={isPasswordMismatch}
                                   helperText={isPasswordMismatch ? strings['REGISTER_VIEW_PASSWORD_MISMATCH'] : null}
                        />

                        <FormRow marginTop={"24px"}>
                            <Label>{strings['REGISTER_VIEW_TERMS_PART_ONE']}
                                <a href='https://legal.asservato.de/datenschutz' target="_privacy">{strings['REGISTER_VIEW_TERMS']}</a>
                                {strings['REGISTER_VIEW_TERMS_PART_TWO']}
                            </Label>
                        </FormRow>

                        {!isLoading
                            ?   <PrimaryButton label={strings['REGISTER_VIEW_BTN_REGISTER']} onClick={onConfirm} isDisabled={!(isValidEmail(email) && isPasswordValid)} marginTop={32}/>
                            :   <LoadingIndicator marginTop={48} padding={'0 0 16px 0'}/>
                        }
                    </>
                :   <HelperDialog>
                        {strings['REGISTER_VIEW_HELPER_TITLE']}<br/><br/>
                        {strings['REGISTER_VIEW_HELPER_TO_LOGIN_DESCRIPTION']} <LabelSemiBold><a href={userDashboardPaths.login}>{strings['REGISTER_VIEW_HELPER_TO_LOGIN_LINK']}</a></LabelSemiBold>.
                        <br/><br/>
                        {strings['REGISTER_VIEW_HELPER_TO_PWRESET_DESCRIPTION']} <LabelSemiBold><a href={userDashboardPaths.forgotPassword}>{strings['REGISTER_VIEW_HELPER_TO_PWRESET_LINK']}</a></LabelSemiBold>.
                    </HelperDialog>
            }
          </Container>
          <Footer>
              {strings['LOGIN_VIEW_BTN_TO_LOGIN']} <a href={userDashboardPaths.login}>{strings['LOGIN_VIEW_BTN_TO_LOGIN_LINK']}</a>{'.'}
          </Footer>

          <LegalLinks>
              <a href='https://legal.asservato.de/datenschutz' target='_blank' rel="noreferrer">{strings['LOGIN_VIEW_LINK_TO_PRIVACY_POLICY']}</a><br/>
              <a href='https://www.asservato.de/impressum' target='_blank' rel="noreferrer">{strings['LOGIN_VIEW_LINK_TO_IMPRINT']}</a>
          </LegalLinks>
      </View>
    );
}

const View = styled.div`
    width: 100%;
    height: 100vh;
    background: linear-gradient(45deg, #091541, #233460);//${colors.primary.primary_200};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    @media ${breakpoints.mobileL} {
        justify-content: center;
    }
    
`;

const Container = styled.div`
    background-color: white;
    width: 100%;
    max-width: 540px;
    padding: 24px;
    box-sizing: border-box;

    @media ${breakpoints.mobileL} {
        padding: 72px;
        border-radius: 5px;
    }
`;

const LogoContainer = styled.div`
    text-align: center;
`;

const Logo = styled.img`
    max-height: 40px;
`;

const Description = styled(Body)`
    padding-top: 32px;
    text-align: left;
    color: ${colors.primary.primary_100};
    display: block;
`;

const FormRow = styled.div`
    margin-top: ${props => props.marginTop || null};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Label = styled(LabelRegularSmall)`
    text-align: left;
    a {
        color: ${colors.tertiary.tertiary_100};
    }
`;

const Footer = styled(Body)`
    padding: 32px 24px;
    text-align: left;
    color: white;
    display: block;
    
    a {
        color: white !important;
    }
`;

const LegalLinks = styled(Body)`
    padding: 0 24px 32px 24px;
    text-align: center;
    color: white;
    display: block;
    
    a {
        color: white !important;
        text-decoration: none;
        opacity: 55%;
        line-height: 1.7em;
    }

    a:hover {
        opacity: 80%;
    }
`;

const HelperDialog = styled.div`
    margin-top: 24px;
    //background: ${colors.primary.primary_100};
    border: 1px solid ${colors.primary.primary_100};
    color: ${colors.primary.primary_200};
    padding: 16px;
    text-align: left;

    a {
        color: ${colors.tertiary.tertiary_100} !important;
    }
`;


