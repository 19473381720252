import React, {useState} from "react";
import {Button, FormControl, OutlinedInput} from "@mui/material";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { useAppState } from "contexts/AppStateContext";
import FunctionsApiService from "services/FunctionsApiService";
import {from} from "rxjs";
import {useSnackbar} from "notistack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Select from 'components/Select';

export default function CustomerServiceView() {
    const {strings, setIsLoading} = useAppState();
    const [inquiryType, setInquiryType] = useState('');
    const [message, setMessage] = useState('');
    const {enqueueSnackbar} = useSnackbar();

    const handleSubmitClick = () => {
        setIsLoading(true);

        from(FunctionsApiService.inquiriesRequest({
            type: inquiryType,
            message: message,
        })).subscribe({
            next: d => {
                setInquiryType('');
                setMessage('');
                setIsLoading(false);
                enqueueSnackbar(strings['CUSTOMER_SERVICE_VIEW_NOTIFICATION_REQUEST_SENT'], {
                    variant: 'success',
                });
            }, error: error => {
                setIsLoading(false);
                enqueueSnackbar(strings['ERROR_customer_support/request-failed'], {
                    variant: 'error',
                });
            }
        });
    };

    return (

        <div className="customer-service-container">

            <div className="page-header">
                <Button className="mobile-icon" onClick={() => document.body.classList.add('show-mobile-menu')}>
                    <img className="menu-icon"
                         srcSet={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png, ${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white@2x.png 2x`}
                         src={`${process.env.PUBLIC_URL}/assets/menu-icons/icn_menu_white.png`}
                         alt="Menu Icon"
                    />
                </Button>

                {strings["CUSTOMER_SERVICE_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="padded-container">
                <p id="customer-service-text">{strings["CUSTOMER_SERVICE_VIEW_DESCRIPTION"]}</p>

                <Select label={strings["CUSTOMER_SERVICE_VIEW_INQUIRY_TYPE_PLACEHOLDER"]} disabled={false} onChange={(e) => setInquiryType(e.target.value)} value={inquiryType}
                           options={[
                               {value: strings["CUSTOMER_SERVICE_VIEW_INQUIRY_TYPE_0_VALUE"], label:strings["CUSTOMER_SERVICE_VIEW_INQUIRY_TYPE_0"]},
                               {value: strings["CUSTOMER_SERVICE_VIEW_INQUIRY_TYPE_1_VALUE"], label:strings["CUSTOMER_SERVICE_VIEW_INQUIRY_TYPE_1"]},
                               {value: strings["CUSTOMER_SERVICE_VIEW_INQUIRY_TYPE_2_VALUE"], label:strings["CUSTOMER_SERVICE_VIEW_INQUIRY_TYPE_2"]},
                               {value: strings["CUSTOMER_SERVICE_VIEW_INQUIRY_OTHER_VALUE"], label:strings["CUSTOMER_SERVICE_VIEW_INQUIRY_OTHER"]},
                           ]}
                />


                {
                    inquiryType &&
                    <FormControl variant="outlined" className="customer-service-input answer">
                        <OutlinedInput
                            className="disabled-input"
                            id="answer-input"
                            placeholder={strings["CUSTOMER_SERVICE_VIEW_PLACHEOLDER_YOUR_MESSAGE"]}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            aria-describedby="answer-input-label"
                            labelWidth={0}
                            rows={7}
                            multiline
                        />
                    </FormControl>
                }

                <div className="alert-message">
                    <ErrorOutlinedIcon/>
                    <p>{strings["CUSTOMER_SERVICE_VIEW_HINTBOX"]}</p>
                </div>

                <Button variant='contained'
                        disabled={!message}
                        className="blue-button user-data-button"
                        onClick={handleSubmitClick}>
                    {strings["CUSTOMER_SERVICE_VIEW_BTN_SEND_REQUEST"]}
                </Button>
            </div>
        </div>

    )
}
