import React from 'react';
import styled from 'styled-components';

import LoadingIndicator from "./LoadingIndicator";

import {colors, fonts} from "styles/stylesProxy";
import * as Icon from "react-feather";

export default function ProgressListItem({isLoading, isDone, loadingLabel, doneLabel}){
    return (
        <Container>

            {!isDone && !isLoading
                ? <Icon.Lock size={24} color={colors.primary.primary_450}/>
                : null
            }

            {isLoading && !isDone
                ? <LoadingIndicator size={24}/>
                : null
            }

            {isDone
                ? <Icon.Check size={24} color={colors.secondary.secondary_100}/>
                : null
            }

            <Label isLoading={isLoading} isDone={isDone}>
                {!isDone
                    ? loadingLabel
                    : doneLabel
                }
            </Label>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    min-width: 230px;
`;

const Label = styled(fonts.LabelSemiBold)`
    color: ${props => props.isLoading || props.isDone ? colors.primary.primary_100 : colors.primary.primary_450};
    margin-left: 24px;
`;

