import styled from 'styled-components';
import {colors, fonts} from "styles/stylesProxy";
import {cardData} from "constants/card-data";
import useBoxInfo from "hooks/useBoxInfo";
import {useAppState} from "contexts/AppStateContext";
import {useCallback, useState} from "react";
import {from} from "rxjs";
import FunctionsApiService from "services/FunctionsApiService";
import {useSnackbar} from "notistack";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {PartnerRequestStatus} from "constants/enums_partner_request";
import {useHistory} from "react-router-dom";
import {userDashboardPaths} from "constants/paths";

export default function PartnerRequestItem({request}) {
    const {strings, setIsLoading} = useAppState();
    const {boxInfo} = useBoxInfo(request.ownerId, request.boxId, request.tan);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const handleClick = useCallback( () => {
        history.push(userDashboardPaths.accessRequestConfirmation + '/' + request.boxId);
    },[request, history]);

    const handleRequestRejectedClick = useCallback(() => {
        setDialogIsOpen(true);
    }, []);

    const handleRequestRejectedDialogClose = useCallback(e => {
        e.preventDefault();
        setDialogIsOpen(false);
    }, []);

    const handleRequestRejectedDialogConfirm = useCallback(() => {
        setIsLoading(true);

        from(FunctionsApiService.removePartnerRequest({
            boxId: request.boxId,
            partnerId: request.partnerId,
        })).subscribe({
            next: result => {
                setDialogIsOpen(false);
                setIsLoading(false);
            },
            error: err => {
                console.log("Error: ", err);
                setIsLoading(false);
                enqueueSnackbar(strings['ERROR/general'], {variant: 'error'});
            }
        })
    }, [request, enqueueSnackbar, setIsLoading, strings]);

    if(!boxInfo) return <Container/>;

    return (
        <>
            <Container onClick={request.status === PartnerRequestStatus.REJECTED ? handleRequestRejectedClick : handleClick}>
                <Image src={cardData[boxInfo.size.toLowerCase()].imgSrc} alt={boxInfo.name}/>

                <BoxInfo>
                    <fonts.Title>{strings.BOX_LIST_VIEW_ACCESS_REQUESTED}</fonts.Title><br/>
                    <Subtitle>Code {request.tan}</Subtitle><br/>
                    <Subtitle>{strings["GENERAL_BOX_SIZE_" + boxInfo.size]}</Subtitle><br/>
                    {
                        request.status === PartnerRequestStatus.REQUESTED &&
                        <InfoBadge><fonts.LabelSemiBold>{strings.BOX_LIST_VIEW_WAITING_FOR_RESPONSE}</fonts.LabelSemiBold></InfoBadge>
                    }
                    {
                        request.status === PartnerRequestStatus.REJECTED &&
                        <WarningBadge><fonts.LabelSemiBold>{strings.BOX_LIST_VIEW_REQUEST_REJECTED}</fonts.LabelSemiBold></WarningBadge>
                    }

                </BoxInfo>

            </Container>

            <Dialog className="dialog-window"
                    open={dialogIsOpen}
                    onClose={handleRequestRejectedDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <DialogTitle className="bold">{strings["BOX_LIST_VIEW_REQUEST_CANCELLED"]}</DialogTitle>
                    <DialogContentText id="alert-dialog-description">
                        {strings["BOX_LIST_VIEW_REQUEST_CANCELLED_DIALOG"]}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button className="default-button" onClick={handleRequestRejectedDialogConfirm}
                            color="primary">{strings["GENERAL_OK"]}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 24px;
    background-color: ${colors.primary.primary_450};
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 148px;
    
    :hover {
        cursor: pointer;
        opacity: .8;
    }
`;

const Image = styled.img`
    margin-right: 30px;
    width: 100px;
    height: 100px;
    opacity: .4;
`;

const BoxInfo = styled.div`
    text-align: left;
`;

const Subtitle = styled(fonts.Body)`
    color: #A4A2A2;
`;

const InfoBadge = styled.div`
    margin: 8px 0 0 0;
    padding: 3px 8px;
    border-radius: 4px;
    color: ${colors.primary.primary_100};
    background: ${colors.neutral.background};
`;

const WarningBadge = styled.div`
    margin: 8px 0 0 0;
    padding: 2px 8px;
    border-radius: 4px;
    color: ${colors.primary.primary_100};
    background: ${colors.semantic.warning_light};
`;
