import React from 'react';
import styled, { css } from 'styled-components';

import RadioButton from "./RadioButton";
import IconSuccess from "./IconSuccess";
import Badge from './Badge';

import { colors, fonts, breakpoints } from "styles/stylesProxy";

export default function ItemBoxSize({name, subline1, subline2, subline3, price, priceSubline, note, image, isLast, isActive, isSummary, onClick, id, badgeLabel}){
    return (
        <Container isLast={isLast} isActive={isActive} isSummary={isSummary} onClick={onClick ? () => onClick(id) : null}>

            <Left>
                {isSummary
                    ? <IconSuccess/>
                    : <RadioButton isActive={isActive} disableHover={true}/>
                }
            </Left>

            <Right>
                <Text>
                    {badgeLabel &&
                        <BadgeContainer>
                            <Badge label={badgeLabel}/>
                        </BadgeContainer>
                    }

                    <Name>{name}</Name><br/>
                    {(subline1 || subline2 || subline3) &&
                        <SublineContainer>
                            <Subline>{subline1}</Subline><br/>
                            <Subline>{subline2}</Subline><br/>
                            <Subline>{subline3}</Subline>
                        </SublineContainer>
                    }
                    {(price || priceSubline)  &&
                        <>
                            <Price>{price}</Price><br/>
                            <Subline>{priceSubline}</Subline>

                            {isActive && note &&
                            <Note isActive={isActive}>
                                <fonts.LabelRegularSmall>{note}</fonts.LabelRegularSmall>
                            </Note>
                            }

                        </>
                    }

                </Text>

                <Image src={image}/>
            </Right>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 24px 24px;
    border: ${props => props.isActive ? `1px solid ${colors.secondary.secondary_100}` : `1px solid ${colors.primary.primary_450}`};
    margin-bottom: ${props => props.isLast ? 0 : '12px'};
    background: ${props => props.isActive ? 'rgba(30,226,200, .1)' : 'transparent'};
    cursor: ${props => !props.isSummary ? 'pointer' : 'inherit'};
    
    ${(props) => {
    if(!props.isActive && !props.isSummary){
        return css`
                &:hover {
                    border: 1px solid ${colors.primary.primary_300};
                }
            `;
    }
}}
`;

const Left = styled.div`
    position: absolute;
    display: none;

    @media ${breakpoints.mobileL}{
        position: relative;
        display: block;
    }
`;

const Right = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    align-items: center;

    @media ${breakpoints.mobileL}{
        flex-direction: row;
        justify-content: space-between;
    }
`;

const BadgeContainer = styled.div`
    margin-bottom: 6px;
`;

const Text = styled.div`
    text-align: center;
    
    @media ${breakpoints.mobileL}{
        margin-left: 24px;
        margin-right: 8px;
        text-align: left;
    }
`;

const Name = styled(fonts.Title)`
    color: ${colors.primary.primary_100}
`;

const SublineContainer = styled.p`
    line-height:21px;
    margin: 4px 0 8px 0;
`;

const Subline = styled(fonts.LabelRegularSmall)`
    color: ${colors.primary.primary_200};
    padding-bottom: 4px;
`;

const Price = styled(fonts.LabelSemiBold)`
    color: ${colors.primary.primary_100};
`;

const Note = styled.div`
    font-size: 14px;
    line-height: 18px;
    margin-top: 12px;
    padding: 8px 12px;
    border: 1px dashed ${colors.secondary.secondary_100};
    background: ${props => !props.isActive ? 'rgba(30,226,200, .1)' : 'transparent'};
`;

const Image = styled.img`
    max-width:90px;

    @media ${breakpoints.mobileL}{
        max-width:120px;
    }
`;
