import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import styled from "styled-components";

import {getFormattedPrice} from "utils/mobile-app-helpers";
import FunctionsApiService from "services/FunctionsApiService";

import {userDashboardPaths} from "constants/paths";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Button} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PaymentMethodSelection from "components/PaymentMethodSelection";
import ButtonPrimary from "components/PrimaryButton";
import InfoBox from 'components/InfoBox';

import { useAppState } from "contexts/AppStateContext";
import useBox from "hooks/useBox";
import usePrices from "hooks/usePrices";
import {useSnackbar} from "notistack";
import {colors} from "styles/colors";
import {LabelSemiBold, LabelRegularSmall, Body} from "styles/fontStyles";
import LoadingView from "components/LoadingView";
import LoadingIndicator from "components/LoadingIndicator";

export default function ConfirmBookingAccessView() {
    const {strings, goBack, customer, subscriptionPrices} = useAppState();
    const history = useHistory();
    const {boxId} = useParams();
    const {box, latestPartnerRequest} = useBox(boxId);
    const {partnerPrices, fees } = usePrices(box?.location);
    const {enqueueSnackbar} = useSnackbar();
    const [subscriptionInterval, setSubscriptionInterval] = useState();
    const [partnerPlan, setPartnerPlan] = useState();
    const [partnerActivationFee, setPartnerActivationFee] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState({
        braintreeType: '',
        braintreeEmail: '',
        braintreeCardType: '',
        braintreeCardLastTwo: '',
        braintreeCardLastFour: '',
        braintreeNonce: '',
        email:'',
        firstName:'',
        lastName:'',
        address:null,
    })

    useEffect(() => {
        setIsLoading(true);
        if(latestPartnerRequest && partnerPlan && partnerActivationFee) setIsLoading(false)
    }, [latestPartnerRequest, partnerPlan, partnerActivationFee, setIsLoading]);

    useEffect( () => {

        if(!subscriptionPrices || !partnerPrices || !fees) return;

        const interval = subscriptionPrices[boxId].interval.unit;
        const plan = partnerPrices[0]?.plans?.filter( el => el.interval.unit === interval)[0];
        setSubscriptionInterval(interval);
        setPartnerPlan(plan);
        setPartnerActivationFee(fees?.filter(el => el.product.ref === plan.requires[0])[0]);

    }, [subscriptionPrices, partnerPrices, fees, boxId])

    const onConfirm = useCallback(() => {

        setIsSaving(true);

        const confirmRequest = async () => {

            try {
                let payload = {
                    boxId:box.id,
                    partnerId:latestPartnerRequest.partnerId,
                    payment:{
                        method:'NONCE',
                        token:formData.braintreeNonce,
                    }
                };

                await FunctionsApiService.confirmPartnerRequest(payload)
                history.push(userDashboardPaths.finishGrantingAccessProcess + '/' + box.id);
            } catch (error) {
                setIsSaving(false);
                enqueueSnackbar(strings['ERROR/general'], {variant: 'error'});
            }
        }

        confirmRequest();

    }, [box, enqueueSnackbar, latestPartnerRequest, history, setIsSaving, strings, formData.braintreeNonce]);

    useEffect(()=>{
        if(customer) {
            const data = {
                email:customer.email,
                firstName:customer.firstName,
                lastName:customer.lastName,
                address:customer.address,
            }
            updateFormData(data);
        }
    }, [customer]);

    const updateFormData = (data) => {
        setFormData(prevState => {
            const combined = {...prevState, ...data};
            return combined;
        });
    }

    if(isLoading){
        return (
            <div style={{height:'100%'}}>
                <div className="page-header overlay-header">
                    <IconButton onClick={goBack} size="large">
                        <ArrowBackIcon/>
                    </IconButton>

                    {strings["SHARED_ACCESS_CHECKOUT_VIEW_TITLE"]}
                    <Button onClick={() => history.push(userDashboardPaths.boxDetails + '/' + box.id)}><CloseRoundedIcon/></Button>
                </div>

                <LoadingView/>
            </div>
        )
    }

    return (
        <View>
            <div className="page-header overlay-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["SHARED_ACCESS_CHECKOUT_VIEW_TITLE"]}
                <Button onClick={() => history.push(userDashboardPaths.boxDetails + '/' + box.id)}><CloseRoundedIcon/></Button>
            </div>

            <Content>

                <p>
                    <Body>{strings["SHARED_ACCESS_CHECKOUT_VIEW_INTRO"]}</Body>
                </p>
                <p>
                    <Body>{strings["SHARED_ACCESS_CHECKOUT_VIEW_DESCRIPTION"]}</Body>
                </p>

                <PartnerContainer>
                    <PartnerInfo>
                        <LabelSemiBold>{latestPartnerRequest.partnerData.firstName + ' ' + latestPartnerRequest.partnerData.lastName}</LabelSemiBold>
                        <span>{latestPartnerRequest.partnerData.email}</span>
                    </PartnerInfo>

                    <CheckCircleIcon style={{color:colors.secondary.secondary_100}}/>
                </PartnerContainer>

                <InfoBox message={strings["SHARED_ACCESS_CHECKOUT_VIEW_HINTBOX"]}/>

                <PaymentSummary>
                    <LabelSemiBold>{strings["SHARED_ACCESS_CHECKOUT_VIEW_PRICE_HEADLINE"]}</LabelSemiBold>

                    <PaymentItemsContainer>
                        <PaymentItem>
                            <div>
                                <Body>{strings["GENERAL_INTERVAL_"+subscriptionInterval]}</Body><br/>
                                <Hint>{strings["SHARED_ACCESS_CHECKOUT_VIEW_PRICE_HINT"]}</Hint>
                            </div>
                            <Body>{getFormattedPrice(partnerPlan.price.unit)}€</Body>
                        </PaymentItem>
                        <PaymentItem>
                            <Body>{strings["SHARED_ACCESS_CHECKOUT_VIEW_PRICE_ACTIVATION_FEE"]}</Body>
                            <Body>{getFormattedPrice(partnerActivationFee.plans[0].price.unit)}€</Body>
                        </PaymentItem>
                    </PaymentItemsContainer>

                </PaymentSummary>

                <PaymentMethodSelection formData={formData}
                                        updateFormData={updateFormData}
                                        onPaymentSelected={() => {}}
                                        on3DSValidationFailed={() => {}}
                                        onStarted={()=>{}}
                                        isDisabled={false}
                                        price={5}
                />

                {formData.braintreeNonce
                    ?   isSaving
                        ?   <LoadingIndicator marginTop={32}/>
                        :   <ButtonPrimary marginTop={24} label={strings["CHECKOUT_SUMMARY_VIEW_BTN_CONFIRM_PAYMENT"]} onClick={onConfirm}/>
                    :   null
                }
            </Content>
        </View>
    );
}

const View = styled.div`
  text-align: left;
`;

const Content = styled.div`
    width: 100%;
    max-width: 640px;
    margin: auto;
    padding: 25px;
    box-sizing: border-box;
`;

const PartnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.neutral.background};
    padding: 15px 10px;
    color: black;
    margin-top: 15px;
`;


const PartnerInfo = styled.div`
    color: ${colors.primary.primary_100};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;


const PaymentSummary = styled.div`
    margin-top: 32px;
`;

const PaymentItemsContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 24px;
`;

const PaymentItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
`;

const Hint = styled(LabelRegularSmall)`
    color: ${colors.primary.primary_300};
`;
