import React, {useCallback, useEffect} from 'react';
import {useCheckoutState} from "concerns/checkout/contexts/CheckoutStateContext";
import {useTranslation} from "react-i18next";

import PageHeaderLinearFlow from "components/PageHeaderLinearFlow";
import ButtonPrimary from "components/PrimaryButton";

import * as S from '../styles/sharedStyles';
import {checkoutPaths} from "navigation/CheckoutRoutes";
import {dashboardPaths} from "navigation/DashboardRoutes";
import {useHistory} from "react-router-dom";

export default function CheckoutCompleteView({ rootPath, prevPath, nextPath, index }){

    const { updateFormData, resetCheckoutData} = useCheckoutState();
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(()=>{
        updateFormData({heroImage:"../assets/checkout/hero_4.jpg"});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onConfirm = useCallback(async () => {
        resetCheckoutData();
        history.replace(dashboardPaths.boxesOverview);
    }, [resetCheckoutData, history])

    return (
        <S.View>
            <PageHeaderLinearFlow
                totalSteps={0}
                showBackButton={false}
            />

            <S.Content>

                <S.Description>
                    {rootPath === checkoutPaths.checkoutRoot ? t('checkoutComplete.subheading') : t('signupComplete.subheading')}
                </S.Description>

                <ButtonPrimary label={rootPath === checkoutPaths.checkoutRoot ? t('checkoutComplete.cta') : t('checkoutComplete.cta')} marginTop={16} onClick={onConfirm} />
            </S.Content>

        </S.View>
    )
}
