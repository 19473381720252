import React from 'react';
import styled from 'styled-components';
import * as Icon from "react-feather";
import {IconButton} from "@mui/material";
import Pagination from "./Pagination";
import { colors, breakpoints } from "styles/stylesProxy";

export default function PageHeaderLinearFlow({activeStep, totalSteps, decrementActiveStep, onConfirm, isConfirmEnabeled, showBackButton, hideProgressBar}) {

    function onBackButton(){
        decrementActiveStep();
    }

    return (
        <Container>
            <LogoContainer>
                <Logo src={'/assets/logo_asservato.png'}/>
            </LogoContainer>

            <NavigationContainer>

                {showBackButton
                    ?   <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => {
                            onBackButton();
                        }}
                    >
                        <Icon.ArrowLeft color={colors.primary.primary_300} size={24}/>
                    </IconButton>
                    : <ButtonPlaceholder/>
                }

                {!hideProgressBar
                    ?   <Pagination activeStep={activeStep} totalSteps={totalSteps}/>
                    :   null
                }

                {onConfirm
                    ?   <IconButton
                        variant="contained"
                        size="small"
                        onClick={() => {
                            onConfirm();
                        }}
                        disabled={!isConfirmEnabeled}
                    >
                        <Icon.ArrowRight color={isConfirmEnabeled ? colors.secondary.secondary_100 : colors.primary.primary_450} size={24}/>
                    </IconButton>
                    : <ButtonPlaceholder/>
                }

            </NavigationContainer>
        </Container>
    )

}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0px 8px;
    
    @media ${breakpoints.mobileL}{
        padding: 32px 0px 24px;
    }
`;

const Logo = styled.img`
    max-height: 24px;

    @media ${breakpoints.mobileL}{
        max-height: 32px;
    }
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const ButtonPlaceholder = styled.div`
    width: 34px;
    height: 34px;
`;
