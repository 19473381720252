import React, {useState, useEffect} from "react";
import {Button} from "@mui/material";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { useAppState } from "contexts/AppStateContext";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FunctionsApiService from "services/FunctionsApiService";
import {useSnackbar} from "notistack";
import {useHistory, useParams} from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {addThousandSeparator, getFormattedPrice} from 'utils/mobile-app-helpers';
import {userDashboardPaths} from "constants/paths";
import {InsuranceStatus} from '../models/enums';
import useBox from "hooks/useBox";
import usePrices from "hooks/usePrices";
import PaymentMethodSelection from "components/PaymentMethodSelection";
import ButtonPrimary from "components/PrimaryButton";
import Select from 'components/Select';
import InfoBox from "components/InfoBox";
import LoadingView from "components/LoadingView";
import LoadingIndicator from "components/LoadingIndicator";

export default function BoxInsuranceView() {
    const history = useHistory();
    const {strings, goBack, replacePlaceholderInStrings, customer, subscriptionPrices, refreshSubscriptionPrices} = useAppState();
    const {boxId} = useParams();
    const [insuranceId, setInsuranceId] = useState();
    const [amountInsured, setAmountInsured] = useState();
    const [selectedCover, setSelectedCover] = useState('')
    const [insurancePrice, setInsurancePrice] = useState();
    const [confirmedInsuranceSum, setConfirmedInsuranceSum] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const {box, insurance} = useBox(boxId);
    const {insurancePrices} = usePrices(box?.location);

    const [formData, setFormData] = useState({
        braintreeType: '',
        braintreeEmail: '',
        braintreeCardType: '',
        braintreeCardLastTwo: '',
        braintreeCardLastFour: '',
        braintreeNonce: '',
        email:'',
        firstName:'',
        lastName:'',
        address:null,
    })

    useEffect( () => {
        setIsLoading(true);
        if(insurancePrices && subscriptionPrices && customer) setIsLoading(false);
    }, [subscriptionPrices, insurancePrices, customer]);

    useEffect( () => {
        if(!subscriptionPrices) return;

        if(!subscriptionPrices[boxId]) {
            refreshSubscriptionPrices();
        }
    }, [subscriptionPrices, boxId, refreshSubscriptionPrices])

    useEffect(()=>{
        if(insurance){
            setInsuranceId(insurance.id);

            if(insurance.status === InsuranceStatus.COMPLETED && !insurance.cancelling){
                setAmountInsured(insurance.amountInsured);
            } else {
                setAmountInsured(30000);
            }
        }
    }, [insurance]);

    useEffect(()=>{
        if(customer) {
            const data = {
                email:customer.email,
                firstName:customer.firstName,
                lastName:customer.lastName,
                address: customer.address
            }
            updateFormData(data);
        }
    }, [customer]);

    const updateFormData = (data) => {
        setFormData(prevState => {
            return {...prevState, ...data}
        });
    }

    const onSumSelected = (val) => {

        setSelectedCover(val);
        setInsurancePrice(null);

        if(val > 30000){
            const plans = insurancePrices.filter( el => el.product.ref === val.toString())[0].plans;
            const price = plans.filter( el => el.interval.unit === subscriptionPrices[box.id].interval.unit)[0].price.unit;
            setInsurancePrice(price);
        }

    }

    const onConfirm = () => {
        setIsSaving(true);

        let payload = {};
        let promise = null;

        const changeInsurance = async () => {

            if(selectedCover === "30000"){
                payload = {
                    boxId: boxId,
                    insuranceId: insuranceId,
                }
                promise = FunctionsApiService.boxesCancelInsuranceSubscription(payload);
            } else {
                payload = {
                    id: boxId,
                    amount: parseInt(selectedCover)
                }

                payload = {
                    id: boxId,
                    amount:parseInt(selectedCover),
                    payment:{
                        method:'NONCE',
                        token:formData.braintreeNonce,
                    }
                };
                promise = FunctionsApiService.boxesCreateInsuranceSubscription(payload);
            }

            try {
                await promise;

                setConfirmedInsuranceSum(parseInt(selectedCover));
                setSelectedCover('');
                setInsurancePrice(null);
                setIsSaving(false);
            } catch(error) {
                setIsSaving(false);
                enqueueSnackbar(strings['ERROR_insurance_cover/request-failed'], {
                    variant: 'error',
                });
            }

        }

        changeInsurance();


    };

    const onComplete = () => {
        history.replace(userDashboardPaths.boxDetails + '/'+boxId);
    }

    if(isLoading){
        return <LoadingView/>
    }

    return (
        <div className="box-insurance-section">
            <div className="page-header">
                <IconButton onClick={goBack} size="large">
                    <ArrowBackIcon/>
                </IconButton>

                {strings["BOX_INSURANCE_COVER_VIEW_TITLE"]}
                <Button className="placeholder-close-button"><CloseRoundedIcon/></Button>
            </div>

            <div className="padded-container">
                {!confirmedInsuranceSum
                    ?   !box?.cancelling
                            ?   <>
                                    <p className="box-insurance-text">
                                        {strings["BOX_INSURANCE_COVER_VIEW_DESCRIPTION"]}
                                    </p>

                                    <p className="box-insurance-title semi-bold">{strings["BOX_INSURANCE_COVER_VIEW_HEADLINE_CURRENT_COVER"]}</p>
                                    <div className="current-insurance-value">{amountInsured ? addThousandSeparator(amountInsured) + ' €' : strings['GENERAL_LOADING']}</div>
                                    <p className="box-insurance-title semi-bold">{strings["BOX_INSURANCE_COVER_VIEW_HEADLINE_DESIRED_COVER"]}</p>

                                    <Select label={"Bitte auswählen"}
                                            disabled={!insurancePrices}
                                            error={false}
                                            options={[
                                                {value: "30000", label:strings["BOX_INSURANCE_COVER_UNTIL"] + " 30.000 €", disabled:!!(amountInsured === 30000)},
                                                {value: "50000", label:strings["BOX_INSURANCE_COVER_UNTIL"] + " 50.000 €", disabled:!!(amountInsured === 50000)},
                                                {value: "100000", label:strings["BOX_INSURANCE_COVER_UNTIL"] + " 100.000 €", disabled:!!(amountInsured === 100000)},
                                                {value: "250000", label:strings["BOX_INSURANCE_COVER_UNTIL"] + " 250.000 €", disabled:!!(amountInsured === 250000)},
                                                {value: "500000", label:strings["BOX_INSURANCE_COVER_UNTIL"] + " 500.000 €", disabled:!!(amountInsured === 500000)},
                                                {value: "1000000", label:strings["BOX_INSURANCE_COVER_UNTIL"] + " 1.000.000 €", disabled:!!(amountInsured === 1000000)}
                                            ]}
                                            onChange={(e)=>onSumSelected(e.target.value)}
                                            value={selectedCover}
                                            isValid={false}
                                    />

                                    {selectedCover
                                        ? <>
                                            <div className="alert-message">
                                                <ErrorOutlinedIcon/>
                                                {parseInt(selectedCover) === 30000
                                                    ? <p>{strings["BOX_INSURANCE_COVER_VIEW_REQUESTED_DECREASE"]}</p>
                                                    : amountInsured < parseInt(selectedCover)
                                                    ? <p>{strings["BOX_INSURANCE_COVER_VIEW_HINTBOX"]}</p>
                                                    : <p>{strings["BOX_INSURANCE_COVER_VIEW_HINTBOX_LOWER"]}</p>
                                                }
                                            </div>

                                            {insurancePrice
                                                ?   <div className="price-container">
                                                        Betrag

                                                        <div className="price-row">
                                                            <div className="price-left">
                                                                <div className='label'>{strings['GENERAL_INTERVAL_'+subscriptionPrices[box.id].interval.unit]}</div>
                                                                <div className="subline">{strings.BOX_INSURANCE_COVER_VIEW_PRICE_HINT}</div>
                                                            </div>
                                                            <span>{getFormattedPrice(parseFloat(insurancePrice)) + ' €'}</span>
                                                        </div>
                                                    </div>
                                                : null
                                            }

                                            {insurancePrice
                                                ?   <PaymentMethodSelection formData={formData}
                                                        updateFormData={updateFormData}
                                                        onPaymentSelected={() => {}}
                                                        on3DSValidationFailed={() => {}}
                                                        onStarted={() => {}}
                                                        isDisabled={false}
                                                        price={insurancePrice}
                                                    />
                                                :   null
                                            }

                                            {isSaving
                                                ?   <LoadingIndicator marginTop={24}/>
                                                :   (formData.braintreeNonce && insurancePrice) || !insurancePrice
                                                    ?   <ButtonPrimary marginTop={24}
                                                                   label={ !insurancePrice
                                                                       ? strings["BOX_INSURANCE_COVER_VIEW_BTN_CONFIRM_BOOKING"]
                                                                       : strings["BOX_INSURANCE_COVER_VIEW_BTN_CONFIRM_PAYMENT"]
                                                                    }
                                                                   onClick={onConfirm}
                                                        />
                                                    :   null
                                            }

                                        </>
                                        : null
                                    }
                                </>
                            :   <InfoBox message={strings["GENERAL_NOT_AVAILABLE_AFTER_CANCELLATION"]}/>
                    : <>
                            <div className="status-icon-container">
                                <img src={`${process.env.PUBLIC_URL}/assets/check-white.svg`} alt='check-icon' className="payment-status"/>
                            </div>


                            { confirmedInsuranceSum !== 30000
                                ? replacePlaceholderInStrings(strings["BOX_INSURANCE_COVER_VIEW_PURCHASE_COMPLETE"], addThousandSeparator(confirmedInsuranceSum))
                                : replacePlaceholderInStrings(strings["BOX_INSURANCE_COVER_VIEW_PURCHASE_LOWER_COMPLETE"], [addThousandSeparator(confirmedInsuranceSum)])
                            }

                            <Button variant='contained'
                                    className="blue-button user-data-button"
                                    onClick={onComplete}>
                                {strings["GENERAL_DONE"]}
                            </Button>
                        </>
                    }
            </div>
        </div>
    );
}
