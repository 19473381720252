import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {useCheckoutState} from "concerns/checkout/contexts/CheckoutStateContext";
import {useTranslation} from "react-i18next";
import trackEvent, {EVENTS} from "../services/Tracking";

import PageHeaderLinearFlow from "components/PageHeaderLinearFlow";
import ButtonPrimary from "components/PrimaryButton";

import * as S from '../styles/sharedStyles';
import { breakpoints } from "../styles/breakpoints";
import DropdownLocation from "components/DropdownLocation";
import {colors} from "styles/colors";
import {useHistory} from "react-router-dom";
import LoadingIndicator from "components/LoadingIndicator";
import ItemLocation from '../components/ItemLocation';
import {signupPaths} from "navigation/SignupRoutes";

export default function SelectLocationView({ rootPath , prevPath, nextPath, index}) {

    const { formData, updateFormData, activeStep, totalNumSteps, progressBrackets, updateActiveStep, locations } = useCheckoutState();
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        updateFormData({heroImage:"../assets/checkout/location-map-big-"+formData.selectedLocation+".jpg"});

        // only track events in signup
        if(rootPath === signupPaths.signupRoot) trackEvent(EVENTS.WEB_SIGNUP_STARTED);

        updateActiveStep(index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelection = useCallback((selectedToken) => {
        updateFormData({selectedLocation:selectedToken})
        updateFormData({heroImage:"../assets/checkout/location-map-big-"+selectedToken+".jpg"});
    }, [updateFormData])

    const onConfirm = useCallback(async () => {
        //updateActiveStep(nextPath)

        history.push(rootPath + nextPath);
    }, [history, rootPath, nextPath])

    const onBack = useCallback(() => {
        history.replace(rootPath + prevPath);
    }, [history, rootPath, prevPath]);

    if(!locations) return null;

    return (
        <View>
            <TopContainer>
                <PageHeaderLinearFlow
                    activeStep={activeStep}
                    totalSteps={totalNumSteps}
                    decrementActiveStep={onBack}
                    progressBrackets={progressBrackets}
                    onConfirm={onConfirm}
                    isConfirmEnabeled={true}
                />
                <Content>
                    <S.Description>
                        {t('selectLocation.subheading')}
                    </S.Description>

                    {!locations
                        ?   <S.CenteredView>
                                <LoadingIndicator />
                            </S.CenteredView>
                        :   <>
                                <List>
                                    {locations.map((el,i, a)=>{

                                        const isDisabled = el.status === "IN_PREPARATION";
                                        let subtitle = t('selectLocation.locationSoonAvailable');
                                        let addressSplit = el.status === 'OPEN' ? el.address.split(',') : '';

                                        return (
                                            <ItemLocation name={t('selectLocation.location')+ ": " + el.name}
                                                          street={!isDisabled ? addressSplit[0] : subtitle}
                                                          city={!isDisabled ? addressSplit[1] : null}
                                                          isActive={formData.selectedLocation === el.token}
                                                          onClick={!isDisabled ? onSelection : null}
                                                          id={el.token}
                                                          isDisabled={isDisabled}
                                                          key={i}
                                                          isLast={i === a.length -1}
                                            />
                                        );
                                    })}
                                </List>

                                <DropdownContainer>
                                    <DropdownLocation items={locations}
                                                      onSelection={onSelection}
                                                      selectedToken={formData.selectedLocation}
                                                      subtitleSoonAvailable={t('selectLocation.locationSoonAvailable')}
                                    />
                                </DropdownContainer>

                                <ButtonContainer>
                                    <ButtonPrimary isDisabled={false}
                                                   label={t('continue')}
                                                   marginTop={24}
                                                   onClick={onConfirm}
                                    />
                                </ButtonContainer>
                            </>
                    }

                </Content>
            </TopContainer>

            {locations
                ?   <MapContainer>
                        <MapImage src={"../assets/checkout/location-map-big-"+formData.selectedLocation+".jpg"}/>
                    </MapContainer>
                :   null
            }
        </View>
    );

}

const View = styled.div`
    width: 100%;
    height: 100vh;
    @media ${breakpoints.mobileL}{
        width: 420px;
    }
`;
const TopContainer = styled.div`
    background: white;
    box-shadow: 0 2px 5px rgba(0,0,0,.1);
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 16px;
    z-index: 2;
    
    @media ${breakpoints.mobileL} {
        box-shadow: none;
    }
`;

const Content = styled.div`
    padding-bottom: 24px;
    @media ${breakpoints.mobileL} {
        padding-bottom: 40px;
    }
`;

const List = styled.div`
    
    display: none;
    
    @media ${breakpoints.mobileL} {
      display: flex;  
      flex-direction: column;
      display: block;
    }
`;

const DropdownContainer = styled.div`
    @media ${breakpoints.mobileL} {
        display: none;
    }
`;

const MapContainer = styled.div`
    background: ${colors.primary.primary_450};
    margin-left: -16px;
    margin-right: -16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
    
    @media ${breakpoints.mobileL} {
        display: none;
    }
`;

const MapImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding-top: 100px;
`;

const ButtonContainer = styled.div`
    
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
    bottom: 0;
    padding-bottom: 24px;
    z-index: 1;
    
    @media ${breakpoints.mobileL} {
        position: relative;
    }
`;
