import React from 'react';
import styled, {keyframes} from 'styled-components';

import ProgressListItem from "./ProgressListItem";
import IconSuccess from "./IconSuccess";

import {colors, fonts} from "styles/stylesProxy";

let timeoutId = null;

export default function ProgressList({formData, updateFormData, t}){

    if(!formData) return null;

    if(formData.activationSimulationStep < 5){
        if(!timeoutId){
            let id = setTimeout(()=>{
                clearTimeout(timeoutId);
                timeoutId = null;
                let i = formData.activationSimulationStep+1;
                updateFormData({activationSimulationStep:i});
            }, 2000);
            timeoutId = id;
        }
    }

    return (
        formData.reservedBoxNr && formData.activationSimulationStep >= 4
            ? <SuccessContainer>
                <div>
                    <IconSuccess containerSize={48} iconSize={32}/>
                </div>
                <Description>
                    {formData.reservedBoxNr !== "-"
                        ? t('register.progressList.successWithBoxNr', {boxNr: formData.reservedBoxNr})
                        : t('register.progressList.successNoBoxNr')
                    }

                </Description>
            </SuccessContainer>
            :   <Container>
                <Background>
                    <List>
                        <ProgressListItem isLoading={formData.activationSimulationStep === 0} isDone={formData.activationSimulationStep > 0} loadingLabel={t('register.progressList.step1Loading')} doneLabel={t('register.progressList.step1Done')}/>
                        <ProgressListItem isLoading={formData.activationSimulationStep === 1} isDone={formData.activationSimulationStep > 1} loadingLabel={t('register.progressList.step2Loading')} doneLabel={t('register.progressList.step2Done')}/>
                        <ProgressListItem isLoading={formData.activationSimulationStep > 1} isDone={formData.activationSimulationStep > 2 && formData.reservedBoxNr} loadingLabel={t('register.progressList.step3Loading')} doneLabel={t('register.progressList.step3Done')}/>
                    </List>
                </Background>

                <Fineprint>
                    {t('register.progressList.description')}
                </Fineprint>
            </Container>
    )
}

const scaleUp = keyframes`
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
`;

const scaleDown = keyframes`
  from {
    transform: translateY(0) scaleY(1);
  }

  to {
    transform: translateY(-100%) scaleY(0);
  }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    //animation: ${scaleDown} 1s ease-in-out 1;
    transform-origin: top center;
`;

const Background = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background: ${colors.primary.primary_500};
    padding: 96px 24px 72px 24px;
    box-sizing: border-box;
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
`;

const SuccessContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    background: ${colors.primary.primary_500};
    padding: 24px;
    box-sizing: border-box;
    //animation: ${scaleUp} 1s ease-in-out 1;
    transform-origin: bottom center;
`;

const Description = styled(fonts.Body)`
    color: ${colors.primary.primary_100};
    display: inline-block;
    margin-left: 16px;
`;

const Fineprint = styled(fonts.LabelRegularSmall)`
    display: inline-block;
    margin-top: 16px;
    color: ${colors.primary.primary_300};;
`;

