import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import * as Icon from "react-feather"
import {useCheckoutState} from "concerns/checkout/contexts/CheckoutStateContext";
import {useTranslation} from "react-i18next";
import trackEvent, {EVENTS} from "../services/Tracking";

import ButtonPrimary from "components/PrimaryButton";
import PageHeaderLinearFlow from "components/PageHeaderLinearFlow";
import ItemBoxSize from "components/ItemBoxSize";

import * as S from '../styles/sharedStyles';
import * as F from '../styles/fontStyles';
import {colors} from "../styles/colors";
import {useHistory} from "react-router-dom";
import usePrices from "hooks/usePrices";
import LoadingIndicator from "components/LoadingIndicator";
import {signupPaths} from "navigation/SignupRoutes";

export default function SelectBoxSizeView({ rootPath , prevPath, nextPath, index}) {

    const { formData, updateFormData, activeStep, totalNumSteps, progressBrackets, setIsLoading, updateActiveStep} = useCheckoutState();
    const { t } = useTranslation();
    const history = useHistory();
    const { boxPrices } = usePrices(formData.selectedLocation);

    useEffect(() => {
        updateFormData({heroImage:"../assets/checkout/hero_0.jpg"});
        updateActiveStep(index);

        // only track events in signup
        if(rootPath === signupPaths.signupRoot) trackEvent(EVENTS.BRANCH_SELECTED, 'location', formData?.selectedLocation);

        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        setIsLoading(false);
    }, [boxPrices, setIsLoading]);

    const onSelection = useCallback( (size) => {
        updateFormData({selectedSize: size});
    }, [updateFormData]);

    const onConfirm = useCallback(async () => {
        let box = boxPrices.filter(el => el.meta.size === formData.selectedSize)[0];
        updateFormData({selectedBox:box});
        history.push(rootPath + nextPath);

    }, [history, rootPath, nextPath, updateFormData, formData.selectedSize, boxPrices]);

    const onBack = useCallback(() => {
        history.replace(rootPath + prevPath);
    }, [history, prevPath, rootPath]);


    return (
        <S.View>
            <PageHeaderLinearFlow
                activeStep={activeStep}
                totalSteps={totalNumSteps}
                decrementActiveStep={onBack}
                progressBrackets={progressBrackets}
                onConfirm={onConfirm}
                isConfirmEnabeled={formData.selectedSize}
                showBackButton={true}
            />
            <S.Content>


                <S.Description>
                    {t('selectBoxSize.subheading')}
                </S.Description>

                {!boxPrices
                    ?   <S.CenteredView>
                            <LoadingIndicator />
                        </S.CenteredView>
                    :   <>
                            <List>
                                {boxPrices.map((el,i)=>{
                                  return (
                                    <ItemBoxSize name={t('selectBoxSize.sizeCard.title', {title: t('selectBoxSize.'+el.meta.size)})}
                                                 subline1={t('selectBoxSize.sizeCard.size', { width: el.meta.scale.width / 10, height: el.meta.scale.height / 10, depth: el.meta.scale.deep / 10 })}
                                                 subline2={t('selectBoxSize.sizeCard.weightAllowance')}
                                                 //subline2={t('selectBoxSize.sizeCard.details')}
                                                 //price={t('selectBoxSize.sizeCard.price', {price:el.plans[0].price.unit}) + " / " + t('selectBoxSize.sizeCard.month')}
                                                 image={'../assets/checkout/box-'+el.meta.size+'.png'}
                                                 isActive={formData.selectedSize === el.meta.size}
                                                 onClick={onSelection}
                                                 id={el.meta.size}
                                                 key={i}
                                                 badgeLabel={el.meta.size === "STD" ? t('popular').toUpperCase() : null}
                                    />
                                  )
                                })}
                            </List>

                            <BulletList>
                                <BulletItem>
                                    <div><Icon.Check size={16} strokeWidth={3} color={colors.secondary.secondary_100} style={{marginRight:'12px'}}/></div>
                                    {t('selectBoxSize.checklistItem1')}
                                </BulletItem>
                                <BulletItem>
                                    <div><Icon.Check size={16} strokeWidth={3} color={colors.secondary.secondary_100} style={{marginRight:'12px'}}/></div>
                                    {t('selectBoxSize.checklistItem2')}
                                </BulletItem>
                                <BulletItem isLast>
                                    <div><Icon.Check size={16} strokeWidth={3} color={colors.secondary.secondary_100} style={{marginRight:'12px'}}/></div>
                                    <span>{t('selectBoxSize.checklistItem3')}<br/>
                                    </span>
                                </BulletItem>

                                <Fineprint>{t('selectBoxSize.checklistItem3Fineprint')}</Fineprint>
                            </BulletList>

                           <ButtonPrimary isDisabled={!formData.selectedSize}
                                          label={t('continue')}
                                          marginTop={24}
                                          onClick={onConfirm}
                           />
                        </>
                }
            </S.Content>

        </S.View>
    );
}

const List = styled.div`
    display: flex;
    flex-direction: column;
`;

const BulletList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    background: ${colors.primary.primary_500};
    padding: 24px;
`;

const BulletItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${props => props.isLast ? null : '16px'};
`;

const Fineprint = styled(F.LabelRegularSmall)`
    display: inline-block;
    margin-top: 16px;
    color: ${colors.primary.primary_300};;
`;
