import React, {useEffect, useCallback} from 'react';
import * as Icon from "react-feather";
import { createTheme, ThemeProvider, StyledEngineProvider, IconButton } from "@mui/material";

import { CheckoutStateProvider, useCheckoutState } from "concerns/checkout/contexts/CheckoutStateContext"
import styled from 'styled-components';
import * as F from "./styles/fontStyles";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import { HeroImage } from "./components/HeroImage";
import LoadingIndicator from "components/LoadingIndicator";

import NavigationContainer from "./views/NavigationContainer";
import Notification from "components/Notification";
import { breakpoints } from "styles/breakpoints";
import { useAppState } from "contexts/AppStateContext";
import { checkoutPaths } from "navigation/CheckoutRoutes";
import { colors } from "styles/colors";
import { dashboardPaths } from "navigation/DashboardRoutes";
import useSignupQueryHandler from "concerns/checkout/hooks/useSignupQueryHandler";
import {signupPaths} from "navigation/SignupRoutes";
import {CustomerStatus} from "constants/enums_customer";
import {accountSetupPaths} from "navigation/AccountSetupRoutes";

const theme = createTheme({
    typography: {
        fontFamily: ['Aalto Regular', 'sans-serif'].join(',')
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            }
        }
    }
})

export default function CheckoutFlow({rootPath}){

    const { customer } = useAppState();
    return  (rootPath === signupPaths.signupRoot ||  customer !== undefined) && <CheckoutStateProvider>
                <CheckoutContent rootPath={rootPath}/>
            </CheckoutStateProvider>
}

const CheckoutContent = ({rootPath}) => {

    const { activeStep, maxActiveStep, updateActiveStep, totalNumSteps, errorMessage, setErrorMessage, isLoading, updateFormData, formData, resetCheckoutData, checkouts, setTotalNumSteps } = useCheckoutState()
    const { customer, customerPartnerBoxes } = useAppState();
    const { isInitialised } = useSignupQueryHandler((rootPath === signupPaths.signupRoot));
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        let totalSteps = 5;
        if(rootPath === checkoutPaths.checkoutRoot && customer?.status === CustomerStatus.REGISTERED && (checkouts?.length > 0 || customerPartnerBoxes?.length > 0)) totalSteps = 3;
        if(rootPath === accountSetupPaths.accountSetupRoot) totalSteps = 2;
        setTotalNumSteps(totalSteps);
    }, [customer, checkouts, rootPath, setTotalNumSteps, customerPartnerBoxes])

    useEffect( () => {
        if(rootPath === checkoutPaths.checkoutRoot){
            if(customer && !formData.email){
                updateFormData({
                    email: customer.email,
                    validatedEmail: customer.email,
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    useEffect(() => {
        window.onpopstate = event => {
            if(rootPath === signupPaths.signupRoot) {
                // don't allow the user to navigate backwards after the actual checkout has been performed
                if (checkouts?.length > 0) {
                    history.goForward();
                }
            }
        }
    }, [maxActiveStep, updateActiveStep, totalNumSteps, checkouts, rootPath, history]);

    const onClose =  useCallback(()=> {
        resetCheckoutData();
        history.replace(dashboardPaths.boxesOverview);
    }, [resetCheckoutData, history])

    return <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>

        {!isLoading
            ?<SplitView>
                <MainContent>

                    <Notification
                        message={errorMessage}
                        onClose={setErrorMessage}
                    />

                    {isInitialised
                        ? <div>
                            <NavigationContainer
                                activeStep={activeStep}
                                rootPath={rootPath}
                                isShortFlow={totalNumSteps === 3}
                            />
                        </div>
                        : null
                    }
                </MainContent>


                <HeroImage currentImage={formData.heroImage} images={
                    window.innerWidth >= 1024
                        ? [
                            "../assets/checkout/location-map-big-BER.jpg",
                            "../assets/checkout/location-map-big-STR.jpg",
                            "../assets/checkout/hero_0.jpg",
                            "../assets/checkout/hero_1.jpg",
                            "../assets/checkout/hero_2.jpg",
                            "../assets/checkout/hero_3.jpg",
                            "../assets/checkout/hero_4.jpg",
                            "../assets/checkout/box-MAX.png",
                            "../assets/checkout/box-STD.png"
                        ]
                        : ["../assets/checkout/location-map-big-BER.jpg",
                            "../assets/checkout/location-map-big-STR.jpg",
                            "../assets/checkout/box-MAX.png",
                            "../assets/checkout/box-STD.png"
                        ]
                }/>

                {(rootPath === checkoutPaths.checkoutRoot || rootPath === accountSetupPaths.accountSetupRoot) &&
                    <CloseButtonContainer>
                        <IconButton
                            variant="contained"
                            size="small"
                            onClick={onClose}
                        >
                            <Icon.X color={colors.primary.primary_300} size={24}/>
                        </IconButton>
                    </CloseButtonContainer>
                }

            </SplitView>
            :   <LoadingContainer>
                <LoadingIndicator padding={'16px'}/>
                <F.LabelSemiBold>{t('loading')}</F.LabelSemiBold>
            </LoadingContainer>
        }

    </ThemeProvider>
    </StyledEngineProvider>

}

const SplitView = styled.div`

    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    
    @media ${breakpoints.mobileL} {
      padding: 0;
      height: 100%;
      overflow: hidden;
    }
`;

const MainContent = styled.div`

    width: 100%;
    position: relative;
    text-align: left;
    box-sizing: border-box;
    height: 100%;
    
    @media ${breakpoints.mobileL}{
        flex: 1;
        display: flex;
        justify-content: center;
        overflow-y: scroll;
    }
    
    @media ${breakpoints.laptop}{
        box-shadow: 5px 0px 5px rgba(0,0,0,.1);
        z-index: 1;
        position: absolute;
        width: 50%;
    }
`;

const LoadingContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CloseButtonContainer = styled.div`
    display: block;
    position:absolute;
    left:16px;
    top:12px;
    
    @media ${breakpoints.mobileL} {
        left:initial;
        right: 16px;
        top:16px;
    }
`;
