import React from "react";
import styled from 'styled-components';
import {breakpoints, colors} from "styles/stylesProxy";

export function HeroImage({currentImage, images}){

    if(!images) {
        console.warn("No image set for HeroImage");
        return null;
    }

    return (
        <HeroContainer>
            <Preloader>
                {
                    images.map((el, i) => <img src={el} alt={"hero"} key={i}/>)
                }
            </Preloader>
            <ContainedImage img={currentImage} style={{opacity:1}}/>
        </HeroContainer>
    )

}

const HeroContainer = styled.div`
    background:${colors.primary.primary_500};
    display: none;
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    right: 0;
    
    @media ${breakpoints.laptop}{
        width: 50%;
        display: flex;
    }

    @media ${breakpoints.laptopL}{
        flex: 1;
    }

    @media ${breakpoints.desktop}{
        flex: 1;
    }
`;

const ContainedImage = styled.div`
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    transition: ease-in .3s;
    background-image: url(${props => props.img});
    background-position: center;
    background-size: cover;
`;

const Preloader = styled.div`
    display:none;
`;
