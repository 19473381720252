import React from 'react';
import styled from "styled-components";
import * as Icon from "react-feather";
import { colors } from "styles/stylesProxy";

export default function IconSuccess({containerSize, iconSize}){
    return (
        <Container containerSize={containerSize}>
            <Icon.Check size={iconSize || 24} color={'#fff'}/>
        </Container>
    )
}

const Container = styled.div`
    width: ${props => props.containerSize ? props.containerSize + 'px' : '32px'};
    height: ${props => props.containerSize ? props.containerSize + 'px' : '32px'};
    border-radius: 50%;
    background-color: ${colors.secondary.secondary_100};
    display: flex;
    align-items: center;
    justify-content: center;
`;
