import React from 'react';
import styled from 'styled-components';
import { colors } from "styles/stylesProxy";

export default function Pagination({ activeStep, totalSteps}) {

    return (
        <Container>
            {[...Array(totalSteps)].map((el, i, array) => {
                return  <Step active={(i+1 <= activeStep)} isLast={i === array.length-1} key={i}/>
            })}
        </Container>
    )

}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`;

const Step = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: ${props => props.active ? colors.secondary.secondary_100 : colors.primary.primary_450};
    margin-right: ${props => props.isLast ? "0px" : "16px"};
`;


