import React from 'react';
import {Snackbar} from "@mui/material";
import Alert from '@mui/material/Alert';
import styled from "styled-components";

export default function Notification({message, onClose}) {
    return (
        <div>
            <StyledSnackbar open={!!message} autoHideDuration={5000} onClose={() => onClose('')} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <div>
                    <Alert elevation={6} variant="filled" severity="error" onClose={() => onClose('')} >{message}</Alert>
                </div>
            </StyledSnackbar>
        </div>
    );
}

const StyledSnackbar = styled(Snackbar)`
    
    left: 8px;
    top: 8px;
    right: 8px;
    width: inherit;
    transform: none;
    border-radius: 3px;

    .MuiAlert-filledError{
        border-radius: 5px;
    }

    > div {
        width: 100%;
    }
`;
