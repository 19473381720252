import React from 'react';
import styled, { css } from 'styled-components';
import * as Icon from "react-feather";
import { colors, fonts } from "styles/stylesProxy";

export default function DropdownLocationItem({name, street, city, isLast, isDisabled, onClick, id, hasIcon, isActive}){

    return (
        <Container isLast={isLast} isDisabled={isDisabled} onClick={onClick ? () => onClick(id) : null} isActive={isActive} hasIcon={hasIcon}>
            <Left>
                <Name isDisabled={isDisabled}>{name}</Name>
                <Address isDisabled={isDisabled}>{street}</Address>
                <Address isDisabled={isDisabled}>{city}</Address>
            </Left>

            {hasIcon
                ? <Icon.ChevronDown size={24} color={isActive ? colors.secondary.secondary_100 : colors.primary.primary_300} strokeWidth={2}/>
                : null
            }
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 24px;
    border-bottom: 1px solid ${colors.primary.primary_450};
    cursor: ${props => !props.isSummary ? 'pointer' : 'inherit'};
    align-items: center;
    text-align: left;
    
    ${(props) => {
    if(props.isActive){
        return css`
                    border: 1px solid ${colors.secondary.secondary_100};
            `;
    } else if(props.hasIcon){
        return css`
                border: 1px solid ${colors.primary.primary_450};
            `;
    }
}
}`;

const Name = styled(fonts.Title)`
  color: ${props => props.isDisabled ? colors.primary.primary_300 : colors.primary.primary_100};
`;

const Address = styled(fonts.Body)`
  color: ${props => props.isDisabled ? colors.primary.primary_300 : colors.primary.primary_100};
`;

const Left = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column; 
`;
