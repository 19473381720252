import React from 'react';
import styled, {css} from 'styled-components';
import { colors } from "styles/stylesProxy";

export default function RadioButton({value, isActive, isDisabled, onClick, disableHover}){
    return (
        <Container isActive={isActive} isDisabled={isDisabled} onClick={!isActive && !isDisabled && onClick ? () => onClick(value) : null} disableHover={disableHover}>
            {isActive
                ?   <Inner isDisabled={isDisabled}/>
                :   null
            }
        </Container>
    )
}

const Container = styled.div`
    background: ${props => props.isDisabled ? colors.primary.primary_500 : colors.primary.primary_500};
    border: 1px solid ${props => props.isDisabled ? colors.primary.primary_450 : props.isActive ? colors.secondary.secondary_100 : colors.primary.primary_400};
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
      ${(props) => {
    if (!props.isActive && !props.isDisabled && !props.disableHover) {
        return css`
                          border: 1px solid ${colors.primary.primary_300};
                          cursor: pointer;
                      `;
    }
}
}
`;

const Inner = styled.div`
    width: 20px;
    height: 20px;
    background: ${props => props.isDisabled ? colors.primary.primary_450 : colors.secondary.secondary_100};
    border-radius: 10px;
`;

