import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function DialogBasic({ handleClose, isOpen, title, description, actions }) {
    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle id="dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        actions?.map((el, index) => {
                            return <Button onClick={el.callback} key={index}>{el.label}</Button>;
                        })
                    }
                </DialogActions>
            </ Dialog>
        </div>
    )
}
